<template>
<div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="guests" sort-by="calories" class="elevation-1" :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
    }">
      <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Hóspedes</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                  <v-menu :offset-y="true">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              color="primary"
              outlined
              icon
              large
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
                    <v-icon >
                        {{ icons.mdiTools }}
                    </v-icon>
            </v-btn>
          </template>
          <span>Ferramentas</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item @click="sendPrintSheetGuest()">
          <v-list-item-title >Imprimir ficha em branco</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer></v-spacer>
        <v-text-field :append-icon="icons.mdiMagnify" @click:append="confirmSearch()"  class="mt-6" dense rounded outlined label="Pesquisar" @keyup.enter="confirmSearch()" v-model="search"></v-text-field>
                <!-- <v-btn color="primary" large icon outlined>
                  <v-icon >
                        {{ icons.mdiTools }}
                    </v-icon></v-btn> -->
                <v-spacer></v-spacer>
                <v-btn color="primary" dark rounded class="mb-2" @click="showDialogNewGuest()">
                    <v-icon small>
                        {{ icons.mdiPlus }}
                    </v-icon>
                    Novo hóspede
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
<div style=" white-space: nowrap;">
             <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :loading="item.loading_print" :disabled="item.loading_print" color="secondary"  class="mr-2" @click="getExportPdf(item)" v-bind="attrs" v-on="on">
                  <v-icon  >
                    {{ icons.mdiPrinter  }}
                </v-icon>
                </v-btn>

            </template>
            <span>Imprimir</span>
            </v-tooltip>



             <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">
                  {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>Editar</span>
            </v-tooltip>


             <v-tooltip top>
              <template v-slot:activator="{ on, attrs }" >
              <v-icon color="error" @click="deleteItem(item)" v-bind="attrs" v-on="on">
                  {{ icons.mdiDeleteOutline }}
              </v-icon>
              </template>
            <span>Excluir</span>
            </v-tooltip>
        </div>
        </template>
        <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template>
        <template v-slot:loading>
            Carregando...
        </template>
    </v-data-table>
    <DialogNewGuestVue v-model="dialogNewGuest" :index="editedIndex" :in_data='editedItem' @out_data='saveConfirm' />
    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
</div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import { mdiClose, mdiDeleteOutline, mdiPencilOutline, mdiPlus, mdiPrinter, mdiTools, mdiMagnify } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'
import DialogNewGuestVue from './_components/DialogNewGuest.vue'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    DialogNewGuestVue,
  },
  data: () => ({
    search: '',
    loadingPrint: false,
    dialogNewGuest: false,
    editedIndex: -1,
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'NOME',
        value: 'name',
      },
      {
        text: 'TELEFONE',
        value: 'cell_phone',
      },
      {
        text: 'EMAIL',
        value: 'email',
      },

      {
        text: '',
        align: 'end',
        value: 'actions',
        sortable: false,
      },
    ],
    guests: [],
    editedIndex: -1,
    editedItem: {},
    options: {},
    tab: null,
    items: [
      {
        tab: 'One',
        content: 'Tab 1 Content',
      },
      {
        tab: 'Two',
        content: 'Tab 2 Content',
      },
    ],
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiPrinter,
      mdiTools,
      mdiMagnify,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Hospéde' : 'Editar Hospéde'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.initialize()
      },
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('guest', ['getAll', 'store', 'update', 'delete', 'exportPdf', 'printSheetGuest', 'setFamiliars']),
    ...mapMutations('guest', ['setFamiliars']),
    initialize() {
      this.loadingTable = true

      const { page, itemsPerPage } = this.options

      const payload = {
        itemsPerPage: itemsPerPage || 10,
        page,
        search: this.search,
      }

      this.getAll(payload)
        .then(response => {
          this.guests = response.data.data
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    confirmSearch() {
      this.initialize()
    },
    getExportPdf(guest) {
      guest.loading_print = true

      this.exportPdf(guest)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export_guest.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.guests.forEach(item => {
            item.loading_print = false
          })
          //armegagem para força a reação do vue
          let backup = this.guests
          this.guests = []
          this.guests = backup
        })
    },
    showDialogNewGuest() {
      this.setFamiliars([])
      this.editedIndex = -1,
      this.dialogNewGuest = true
    },
    editItem(item) {
      this.editedIndex = this.guests.indexOf(item)
      this.editedItem = {
        ...item,
      }

      this.setFamiliars(item.familiar)
      this.dialogNewGuest = true
    },

    saveConfirm(guest) {
      if (this.editedIndex == -1) {
        this.guests.push(guest) //adiciona no array
      } else {
        Object.assign(this.guests[this.editedIndex], guest) // atualiza o guest no array
      }
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.editedItem = item
    },

    deleteItemConfirm() {
      const index = this.guests.indexOf(this.editedItem)
      this.delete(this.editedItem)
        .then(() => {
          this.guests.splice(index, 1)
          this.closeDelete()
          this.$toast.success('Excluído com sucesso !')
        })
        .catch()
        .finally()
    },

    closeDelete() {
      this.dialogDelete = false
      this.editedIndex = -1
    },
    sendPrintSheetGuest() {
      this.printSheetGuest()
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'sheet_guest.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {})
    },
  },
}
</script>

<style scoped>
.card-tab {
  min-height: 420px;
  min-width: 736px;
}

.tool_table_fam {
  height: 50px;
}
</style>
